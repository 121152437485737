import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchRequests(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/host-course-requests', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchRequest(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/host-course-requests/' + id)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    }
}
